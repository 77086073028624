import * as history from '../wrappers/history';
import _ from 'lodash';
import * as membersLogic from '../membersLogic';
import * as memberPages from '../pages';
import * as menus from '../wrappers/menus';
import * as routers from '../wrappers/routers';
import * as pages from '../wrappers/pages.ts';
import enforceSequentiality from '../enforceSequentiality.ts';
import { APP_TOKEN, PERMISSION_STATES } from '../constants';
import { toMonitored } from '../../utils/monitoring';
import { removeMembersAreaPage } from '../platform-api/removeMembersAreaPage';

export default (editorSDK) => {
  const BLOG_APP_ID = '14bcded7-0066-7c35-14d7-466cb3f09103';
  const FORUM_APP_ID = '14724f35-6794-cd1a-0244-25fd138f9242';
  const FF_APP_ID = '14ebe801-d78a-daa9-c9e5-0286a891e46f';
  const ABOUT_APP_ID = '14dbef06-cc42-5583-32a7-3abd44da4908';
  const SOCIAL_GROUPS_APP_ID = '148c2287-c669-d849-d153-463c7486a694';
  const EVENTS_APP_ID = '140603ad-af8d-84a5-2c80-a0f60cb47351';

  const isAppInstalled = async (appDefinitionId) => {
    return await editorSDK.document.tpa.isApplicationInstalled(APP_TOKEN, { appDefinitionId });
  };

  return {
    hasSocialPages: async () => {
      return enforceSequentiality(async () => {
        const appRouters = await editorSDK.routers.getAll();
        const socialPages = appRouters.filter(
          (r) => r.config.type === 'public' && r.config.patterns && Object.keys(r.config.patterns).length > 0,
        );
        const blogInstalled = await isAppInstalled(BLOG_APP_ID);
        const forumInstalled = await isAppInstalled(FORUM_APP_ID);
        const ffInstalled = await isAppInstalled(FF_APP_ID);
        const aboutInstalled = await isAppInstalled(ABOUT_APP_ID);
        const socialGroupsInstalled = await isAppInstalled(SOCIAL_GROUPS_APP_ID);
        const eventsInstalled = await isAppInstalled(EVENTS_APP_ID);
        return (
          blogInstalled ||
          forumInstalled ||
          ffInstalled ||
          aboutInstalled ||
          socialGroupsInstalled ||
          eventsInstalled ||
          socialPages.length > 0
        );
      });
    },
    removePage: async (pageData) => {
      return enforceSequentiality(() => removeMembersAreaPage({ editorSDK, id: pageData.id }));
    },
    uninstall: () => {
      return enforceSequentiality(() =>
        toMonitored('uninstall', async () => {
          await membersLogic.uninstall(editorSDK);
        }),
      );
    },
    getRouterDataByPage: (pageRef) => {
      return enforceSequentiality(async () => await routers.getPageRouterData(editorSDK, pageRef));
    },
    getAllRouters: () => {
      return enforceSequentiality(async () => await routers.getAll(editorSDK));
    },
    updatePageDataInRouter: (pageRef, dataFieldToUpdate, updatedData) => {
      return enforceSequentiality(
        async () => await routers.updatePageData(editorSDK, pageRef, dataFieldToUpdate, updatedData),
      );
    },
    saveInnerRoute: (newInnerRoute, pageRef, pageRouterData) => {
      return enforceSequentiality(async () => {
        let savedInnerRoute = await routers.changePagePattern(editorSDK, pageRef, newInnerRoute);
        if (savedInnerRoute && savedInnerRoute[0] === '/') {
          savedInnerRoute = savedInnerRoute.substr(1);
        }

        await menus.updateMenuItemInAllMenus({
          editorSDK,
          pageRouterData: pageRouterData,
          updatedData: { link: { innerRoute: savedInnerRoute } },
        });
        history.addWithDelay(editorSDK, 'Members update uri seo');
      });
    },

    /* Pages */
    updatePageData: async ({ pageRef, pageData }) => {
      enforceSequentiality(async () => await pages.updatePageData({ editorSDK, pageRef, pageData }));
    },
    savePasswordPermissions: async (pageRef, privacy, plainPassword) => {
      if (privacy === PERMISSION_STATES.PASSWORD && plainPassword) {
        await pages.updatePageData({ editorSDK, pageRef, pageData: { pageSecurity: { requireLogin: false } } });
        enforceSequentiality(async () => await pages.updatePagePassword({ editorSDK, pageRef, plainPassword }));
      } else {
        const shouldRequireLogin = !_.isEqual(privacy, PERMISSION_STATES.NONE);
        await pages.updatePageData({
          editorSDK,
          pageRef,
          pageData: { pageSecurity: { requireLogin: shouldRequireLogin } },
        });
        enforceSequentiality(async () => await pages.removePagePassword({ editorSDK, pageRef }));
      }
    },
    /* PageInfo */
    renamePage: async (pageName, pageRef, pageRouterData) => {
      enforceSequentiality(async () => {
        const PpageRename = pages.rename({
          editorSDK,
          pageRef,
          title: pageName,
        });
        const PmenuUpdateItem = menus.updateMenuItemInAllMenus({
          editorSDK,
          pageRouterData,
          updatedData: { label: pageName },
        });
        const ProuterDataUpdate = routers.updatePageData(editorSDK, pageRef, 'title', pageName);
        const ProuterSeoTitleUpdate = routers.updatePageData(editorSDK, pageRef, 'seoData', { title: pageName });

        await Promise.all([PpageRename, PmenuUpdateItem, ProuterDataUpdate, ProuterSeoTitleUpdate]);
        history.add(editorSDK, 'Members page rename');
      });
    },
  };
};
