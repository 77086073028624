import * as routersService from './routers';
import * as menusService from './menus';
import * as pagesWrapper from '../wrappers/pages.ts';
import * as pagesGroupWrapper from '../wrappers/pagesGroup';
import * as tpaWrapper from '../wrappers/tpa';
import { MEMBERS_PAGES_GROUP_NAME, SANTA_MEMBERS_APP_ID, UNDELETABLE_PAGES } from '../constants';
import { log } from '../../utils/monitoring';

async function setProtectedPage({ editorSDK, page }) {
  const existingPageData = await pagesWrapper.getPageData({ editorSDK, pageRef: page.pageData.pageRef });
  if (existingPageData && existingPageData.pageSecurity && !existingPageData.pageSecurity.requireLogin) {
    await pagesWrapper.updatePageData({
      editorSDK,
      pageRef: page.pageData.pageRef,
      pageData: { pageSecurity: { requireLogin: true } },
    });
  }
}

function setProtectedPages({ editorSDK, pages }) {
  return Promise.all(pages.map((page) => setProtectedPage({ editorSDK, page })));
}

function addPagesToPagesGroup({ editorSDK, pages }) {
  return Promise.all(
    pages.map((page) =>
      pagesGroupWrapper.addPageToGroup(editorSDK, MEMBERS_PAGES_GROUP_NAME, page.pageData.pageRef.id),
    ),
  );
}

function updatePageWithManagingAppDefId({ editorSDK, pageRef }) {
  return pagesWrapper.updatePageData({ editorSDK, pageRef, pageData: { managingAppDefId: SANTA_MEMBERS_APP_ID } });
}

async function connectPagesToMembers({ editorSDK, pages }) {
  const { publicRouter, privateRouter } = await routersService.getMembersAreaRouters(editorSDK);
  const privatePages = pages.filter((page) => page.pageData.isPrivate);
  const publicPages = pages.filter((page) => !page.pageData.isPrivate);
  const includesPrivatePages = privatePages.length > 0;
  const includesPublicPages = publicPages.length > 0;

  await Promise.all(
    [
      includesPrivatePages &&
        routersService.connectPagesToRouter({ editorSDK, pages: privatePages, router: privateRouter }),
      includesPublicPages &&
        routersService.connectPagesToRouter({ editorSDK, pages: publicPages, router: publicRouter }),
      includesPrivatePages && setProtectedPages({ editorSDK, pages: privatePages }),
      addPagesToPagesGroup({ editorSDK, pages }),
    ].filter((p) => !!p),
  );

  return menusService.connectPagesToMenus({ editorSDK, pages });
}

async function setStateForPages(editorSDK) {
  const applicationPages = await editorSDK.document.pages.getApplicationPages('');

  const readOnly = [];
  const deletable = [];

  applicationPages.forEach((pageData) => {
    const pageRef = { id: pageData.id, type: 'DESKTOP' };
    if (UNDELETABLE_PAGES && UNDELETABLE_PAGES.includes(pageData.tpaPageId)) {
      readOnly.push(pageRef);
    } else {
      deletable.push(pageRef);
    }
  });
  await editorSDK.document.pages.setState('', {
    state: {
      readOnly,
      deletable,
    },
  });
  return editorSDK.document.application.reloadManifest();
}

async function getPageByIntegrationApp({ editorSDK, app }) {
  const { pageId, appDefinitionId } = app;
  const allPages = await pagesWrapper.getAllPages({ editorSDK });

  let page, applicationId;
  if (appDefinitionId) {
    const appData = await tpaWrapper.getDataByAppDefId({ editorSDK, appDefinitionId });
    applicationId = appData?.applicationId;
    if (!applicationId) {
      log('Invalid getDataByAppDefId', { extra: { appDefinitionId, source: 'getPageByIntegrationApp', app } });
    }
  }

  if (applicationId) {
    page = allPages.find((onePage) => onePage.tpaPageId === pageId && onePage.tpaApplicationId === applicationId);
  } else {
    page = allPages.find((onePage) => onePage.tpaPageId === pageId);
  }
  return page;
}

async function getAllMembersPagesRefs({ editorSDK }) {
  const { publicRouter = {}, privateRouter = {} } = await routersService.getMembersAreaRouters(editorSDK);
  const allMAPages = [...(publicRouter.pages || []), ...(privateRouter.pages || [])];
  const allMAPagesRefs = allMAPages.map((page) => page.pageRef);
  return allMAPagesRefs;
}

async function getApplicationPage(editorSDK, appDefinitionId, pageId) {
  const allPages = await pagesWrapper.getAllPages({ editorSDK });
  const pageData = await tpaWrapper.getDataByAppDefId({ editorSDK, appDefinitionId });
  return allPages?.find((page) => pageData?.applicationId === page.tpaApplicationId && page.tpaPageId === pageId);
}

export {
  connectPagesToMembers,
  updatePageWithManagingAppDefId,
  setStateForPages,
  getPageByIntegrationApp,
  getApplicationPage,
  getAllMembersPagesRefs,
};
