import uuid from 'uuid';
import _ from 'lodash';
import { APP_TOKEN, NEW_PAGE_ROUTE } from '../constants';

function createPageSEOData(pageTitle, isPrivate) {
  return {
    title: isPrivate ? pageTitle : '{userName} | ' + pageTitle,
    description: '',
    keywords: '',
    noIndex: isPrivate.toString(),
  };
}

async function onRouterPageDelete(editorSDK, pageRole, pageRef) {
  const routers = await editorSDK.routers.getAll();
  for (const router of routers) {
    const patternToRemove = _.find(router.config.patterns, (pattern) => pattern.page === pageRole);
    const returnValue = _.findKey(router.config.patterns, patternToRemove);
    if (patternToRemove) {
      const { appDefinitionId } = patternToRemove.appData;
      router.config.patterns = _.pickBy(router.config.patterns, (p) => p.page !== pageRole);
      const routerRef = await editorSDK.routers.getByPrefix(APP_TOKEN, { prefix: router.prefix });
      await editorSDK.routers.update(APP_TOKEN, {
        routerRef,
        config: router.config,
      });
      if (pageRef) {
        await editorSDK.routers.pages.remove(APP_TOKEN, { routerRef, pageRef });
      }

      return { returnValue, appDefinitionId };
    }
  }
  return {};
}

async function getPageRouterData(editorSDK, pageRef) {
  const routerRef = await editorSDK.routers.getByPage(APP_TOKEN, { pageRef });
  const router = await editorSDK.routers.get(APP_TOKEN, { routerRef });

  if (!router) {
    throw new Error('Could not retrieve the router in #getPageRouterData');
  }

  const pageRouterData = _.find(router.pages, (page) => page.pageRef.id === pageRef.id);
  const guid = pageRouterData.pageRoles[0];
  const data = _.find(router.config.patterns, (p) => p.page === guid);
  const pattern = _.findKey(router.config.patterns, (p) => p.page === guid);
  if (data) {
    data.routerRef = routerRef;
    data.innerRoute = pattern;
    data.prefix = router.prefix;
    data.isPrivate = router.config.type === 'private';
  }

  return data;
}

async function updatePageData(editorSDK, pageRef, dataFieldToUpdate, updatedData) {
  const routerRef = await editorSDK.routers.getByPage(APP_TOKEN, { pageRef });
  const router = await editorSDK.routers.get(APP_TOKEN, { routerRef });
  const pageRouterData = _.find(router.pages, (page) => {
    return page.pageRef.id === pageRef.id;
  });
  const guid = pageRouterData.pageRoles[0];
  const pattern = _.findKey(router.config.patterns, (p) => {
    return p.page === guid;
  });
  const patternData = _.find(router.config.patterns, (p) => {
    return p.page === guid;
  });

  patternData[dataFieldToUpdate] =
    typeof updatedData === 'string' ? updatedData : _.assign({}, patternData[dataFieldToUpdate], updatedData);

  const config = router.config;
  _.set(config, 'patterns[' + pattern + ']', patternData);

  await editorSDK.routers.update(APP_TOKEN, {
    routerRef,
    config,
  });
  return routerRef;
}

async function changePagePattern(editorSDK, pageRef, newSuffix) {
  const routerRef = await editorSDK.routers.getByPage(APP_TOKEN, { pageRef });
  const router = await editorSDK.routers.get(APP_TOKEN, { routerRef });
  const pageRouterData = _.find(router.pages, (page) => {
    return page.pageRef.id === pageRef.id;
  });
  const guid = pageRouterData.pageRoles[0];
  const pattern = _.findKey(router.config.patterns, (p) => {
    return p.page === guid;
  });
  const patternData = _.find(router.config.patterns, (p) => {
    return p.page === guid;
  });

  const config = router.config;
  const newPattern = (config.type === 'private' ? '/' : '/{userName}/') + newSuffix;
  delete config.patterns[pattern];
  _.set(config, 'patterns[' + newPattern + ']', patternData);

  await editorSDK.routers.update(APP_TOKEN, {
    routerRef,
    config,
  });
  return newPattern;
}

async function getId(editorSDK, routerRef) {
  const router = await editorSDK.routers.get(APP_TOKEN, { routerRef });
  return router.id;
}

async function getAll(editorSDK) {
  const routersArr = await editorSDK.routers.getAll();
  return routersArr;
}

async function add(editorSDK, routerDef, retry = 0, e) {
  if (retry > 3) {
    throw new Error('Can not add router, failed after 3 retries, reason: ' + e && e.toString());
  }

  const retryAddingRouter = async (previousError) => {
    // Sometimes SDK adds a router but still throws some kind of error, and this method retries.
    // If router is already added in our app scope, let's not add it again
    const existingRouter = await editorSDK.routers.getByPrefix(APP_TOKEN, { prefix: routerDef.prefix });

    if (existingRouter) {
      return existingRouter;
    }

    let { prefix: routerPrefix } = routerDef;
    /* eslint-disable-next-line no-useless-escape */
    routerPrefix = routerPrefix.replace(/\-[0-9]$/, '');
    routerPrefix += `-${++retry}`;
    routerDef.prefix = routerPrefix;
    return add(editorSDK, routerDef, retry, previousError);
  };

  const router = await editorSDK.routers.add(APP_TOKEN, routerDef).catch(retryAddingRouter);

  // Sometimes SDK resolves a router but does not actually add it because of pageUriSEO is already taken
  const addedRouter = await editorSDK.routers.get(APP_TOKEN, { routerRef: router });
  if (!addedRouter) {
    return await retryAddingRouter('Router was not added although the installation was successful');
  }

  return router;
}

async function removeAllRouters(editorSDK) {
  const routers = await editorSDK.routers.getAll();
  for (const routerData of routers) {
    const routerRef = await editorSDK.routers.getByPrefix(APP_TOKEN, { prefix: routerData.prefix });
    await editorSDK.routers.remove(APP_TOKEN, { routerRef });
  }
}

async function removeConnectedPages(editorSDK) {
  const routersArr = await getAll(editorSDK);
  for (const routerData of routersArr) {
    const routerRef = await editorSDK.routers.getByPrefix(APP_TOKEN, { prefix: routerData.prefix });
    for (const page of routerData.pages) {
      await editorSDK.routers.pages.remove(APP_TOKEN, {
        routerRef,
        pageRef: page.pageRef,
      });
    }
  }
}

async function findPageRefByAppData(editorSDK, appDefinitionId, appPageId) {
  const routers = await getAll(editorSDK);
  for (const router of routers) {
    const pattern = _.find(
      router.config.patterns,
      (p) => _.get(p, 'appData.appDefinitionId') === appDefinitionId && _.get(p, 'appData.appPageId') === appPageId,
    ); //eslint-disable-line
    if (pattern) {
      for (const page of router.pages) {
        if (page.pageRoles.includes(pattern.page)) {
          return page.pageRef;
        }
      }
    }
  }
  throw new Error(`Could not find member page for TPA ${appDefinitionId} and section ${appPageId}`);
}

const getPageByRole = (pageRole, routers) =>
  routers
    .filter((router) => router.config && router.config.patterns)
    .flatMap((router) => Object.values(router.config.patterns))
    .find((pattern) => pattern.page === pageRole);

const getPatternByPageRef = (pageRef, router) => {
  const pageRoles = router.pages.find((page) => page.pageRef?.id === pageRef.id).pageRoles;
  if (!pageRoles?.length || !router.config.patterns) {
    return;
  }
  const pattern = Object.keys(router.config.patterns).find((onePattern) =>
    pageRoles.includes(router.config.patterns[onePattern]?.page),
  );

  return pattern;
};

function createNewPageRoute(allRoutes, slug = NEW_PAGE_ROUTE) {
  let route = `/${slug}`;
  let i = 1;
  while (allRoutes.includes(route)) {
    route = `/${slug}-${i}`;
    i++;
  }
  return route.substring(1);
}

function getRouterRefByPrefix({ editorSDK, prefix }) {
  return editorSDK.routers.getByPrefix(APP_TOKEN, { prefix });
}

function connectPageToRouter({ editorSDK, pageRef, pageRoles, routerRef }) {
  return editorSDK.routers.pages.connect(APP_TOKEN, { pageRef, routerRef, pageRoles });
}

function updateRouterConfig({ editorSDK, config, routerRef }) {
  return editorSDK.routers.update(APP_TOKEN, { config, routerRef });
}

function removeRouterPage({ editorSDK, routerRef, pageRef, pageToNavigateAfterRemove }) {
  return editorSDK.routers.pages.remove(APP_TOKEN, { routerRef, pageRef, pageToNavigateAfterRemove });
}

function getByPageRef({ editorSDK, pageRef }) {
  return editorSDK.document.routers.getByPage('', { pageRef });
}

function getByRef({ editorSDK, routerRef }) {
  return editorSDK.document.routers.get('', { routerRef });
}

export {
  add,
  getByPageRef,
  getByRef,
  changePagePattern,
  createNewPageRoute,
  findPageRefByAppData,
  getAll,
  getId,
  getPageRouterData,
  onRouterPageDelete,
  removeAllRouters,
  removeConnectedPages,
  updatePageData,
  getRouterRefByPrefix,
  connectPageToRouter,
  updateRouterConfig,
  removeRouterPage,
  getPageByRole,
  getPatternByPageRef,
};
