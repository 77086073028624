import { APP_TOKEN, MENU_IDS } from '../constants';
import _ from 'lodash';

const BASIC_MENU_ITEM = { type: 'BasicMenuItem', items: [], isVisible: true, isVisibleMobile: true };
const { SUB_MENU_ID, LOGIN_MENU_ID, LOGIN_ICONS_MENU_ID } = MENU_IDS;

async function create(editorSDK) {
  await removeMenus(editorSDK);
  const membersMenuIdPromise = editorSDK.menu.create(APP_TOKEN, {
    menuData: { name: 'Member Menu', items: [] },
    customId: SUB_MENU_ID,
  });
  const loginMenuIdPromise = editorSDK.menu.create(APP_TOKEN, {
    menuData: { name: 'Login Menu', items: [] },
    customId: LOGIN_MENU_ID,
  });
  const loginIconsMenuIdPromise = editorSDK.menu.create(APP_TOKEN, {
    menuData: { name: 'Login Icons', items: [] },
    customId: LOGIN_ICONS_MENU_ID,
  });

  const [members, login, icons] = await Promise.all([
    membersMenuIdPromise.catch(() => SUB_MENU_ID),
    loginMenuIdPromise.catch(() => LOGIN_MENU_ID),
    loginIconsMenuIdPromise.catch(() => LOGIN_ICONS_MENU_ID),
  ]);

  return { members, login, icons };
}

async function removeMenuItems(editorSDK, menuId) {
  const menuData = await editorSDK.menu.getById(APP_TOKEN, { menuId });
  menuData.items = [];
  await editorSDK.menu.update(APP_TOKEN, { menuId, menuData });
}

async function removeMenuItem(editorSDK, menuId, innerRoute) {
  const menuData = await editorSDK.menu.getById(APP_TOKEN, { menuId });
  menuData.items = menuData.items.filter((item) => item.link?.innerRoute !== innerRoute);
  return await editorSDK.menu.update(APP_TOKEN, { menuId, menuData });
}

async function removeMenus(editorSDK) {
  const [subMenuExists, logInMenuExists, logInIconsMenuExists] = await Promise.all([
    editorSDK.menu.getById(APP_TOKEN, { menuId: SUB_MENU_ID }),
    editorSDK.menu.getById(APP_TOKEN, { menuId: LOGIN_MENU_ID }),
    editorSDK.menu.getById(APP_TOKEN, { menuId: LOGIN_ICONS_MENU_ID }),
  ]);

  const menuItemsPromises = [
    subMenuExists && removeMenuItems(editorSDK, SUB_MENU_ID),
    logInMenuExists && removeMenuItems(editorSDK, LOGIN_MENU_ID),
    logInIconsMenuExists && removeMenuItems(editorSDK, LOGIN_ICONS_MENU_ID),
  ].filter((p) => !!p);

  await Promise.all(menuItemsPromises);

  // Menus must be removed after finishing the delete of the menu items
  const menusPromises = [
    subMenuExists && editorSDK.menu.remove(APP_TOKEN, { menuId: SUB_MENU_ID }),
    logInMenuExists && editorSDK.menu.remove(APP_TOKEN, { menuId: LOGIN_MENU_ID }),
    logInIconsMenuExists && editorSDK.menu.remove(APP_TOKEN, { menuId: LOGIN_ICONS_MENU_ID }),
  ].filter((p) => !!p);

  await Promise.all(menusPromises);
}

function createNewMenuItem(linkData) {
  return _.assign({}, BASIC_MENU_ITEM, linkData);
}

function getMenuIds() {
  return {
    members: SUB_MENU_ID,
    login: LOGIN_MENU_ID,
    icons: LOGIN_ICONS_MENU_ID,
  };
}

async function updateMenuItemInAllMenus({ editorSDK, pageRouterData, updatedData }) {
  const menuIds = getMenuIds();
  const updateMembersMenu = updateMenuItem({ editorSDK, pageRouterData, updatedData, menuId: menuIds.members });
  const updateLoginMenu = updateMenuItem({ editorSDK, pageRouterData, updatedData, menuId: menuIds.login });
  const updateIconsMenu = updateMenuItem({ editorSDK, pageRouterData, updatedData, menuId: menuIds.icons });

  return Promise.all([updateMembersMenu, updateLoginMenu, updateIconsMenu]);
}

async function updateMenuItem({ editorSDK, pageRouterData, updatedData, menuId }) {
  const innerRoute = pageRouterData.innerRoute;
  const menuData = await editorSDK.menu.getById(APP_TOKEN, { menuId });
  const menuItem = menuData.items.find((item) => '/' + item.link?.innerRoute === innerRoute);

  if (menuItem) {
    _.merge(menuItem, updatedData);
    await editorSDK.menu.update(APP_TOKEN, { menuId, menuData });
  }
}

async function getMenuItemByRoute({ editorSDK, menuId, innerRoute }) {
  const menuData = await editorSDK.menu.getById(APP_TOKEN, { menuId });
  const menuItem = menuData?.items?.find((item) => '/' + item.link?.innerRoute === innerRoute);
  return menuItem;
}

async function getMenuItems({ editorSDK, menuId }) {
  const menu = await editorSDK.menu.getById(APP_TOKEN, { menuId });

  if (!menu) {
    throw new Error('Could not retrieve the menu ' + menuId);
  }

  return menu.items;
}

function getMenuById({ editorSDK, menuId }) {
  return editorSDK.menu.getById(APP_TOKEN, { menuId });
}

async function removePatternFromMenu({ editorSDK, menuId, pattern }) {
  const menuData = await editorSDK.menu.getById(APP_TOKEN, { menuId });
  const newMenuItems = [];
  for (const item of menuData.items) {
    if (item.link.innerRoute !== pattern) {
      newMenuItems.push(item);
    }
  }
  menuData.items = newMenuItems;
  await editorSDK.menu.update(APP_TOKEN, { menuId, menuData });
}

async function removePatternFromAllMenus({ editorSDK, pattern }) {
  const menuIds = getMenuIds();
  for (const menuId of Object.values(menuIds)) {
    await removePatternFromMenu({ editorSDK, menuId, pattern });
  }
}

async function updateMenuItems({ editorSDK, menuId, items }) {
  const menuData = await editorSDK.menu.getById(APP_TOKEN, { menuId });
  menuData.items = items;
  await editorSDK.menu.update(APP_TOKEN, { menuId, menuData });
}

export {
  createNewMenuItem,
  create,
  getMenuById,
  getMenuIds,
  getMenuItemByRoute,
  getMenuItems,
  removeMenus,
  removeMenuItem,
  removePatternFromAllMenus,
  updateMenuItem,
  updateMenuItemInAllMenus,
  updateMenuItems,
};
