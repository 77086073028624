import { addComponent } from '../wrappers/tpa';
import { updateComponentLayout, getById, getComponentChildren, getComponentLayout } from '../wrappers/components';
import { PageWidget } from '@wix/members-area-integration-kit';
import { EditorSDK } from '@wix/platform-editor-sdk';
import { APP_TOKEN, PROFILE_WIDGET_APP } from '../constants';

const CARD_LAYOUT_WIDTH = 300;

export async function putWidgetOnPage(editorSDK, widget: PageWidget, pageId) {
  const startingPoint = await getStartingPoint(editorSDK, pageId);
  const ref = await addComponent(editorSDK, {
    componentType: editorSDK.tpa.TPAComponentType.Widget,
    appDefinitionId: widget.appDefinitionId,
    widget: {
      widgetId: widget.widgetId,
      wixPageId: pageId,
      allPages: false,
    },
  });

  const componentRef = await getById({ editorSDK, id: ref.compId });
  if (startingPoint) {
    return updateComponentLayout({ editorSDK, componentRef, layout: startingPoint });
  }
  return Promise.resolve();
}

async function getStartingPoint(editorSDK: EditorSDK, pageId) {
  const pageRef = await getById({ editorSDK, id: pageId });
  if (!pageRef) {
    return;
  }
  const res = { x: 0, y: 0 };

  const profileApi: any = await editorSDK.application.getPublicAPI(APP_TOKEN, {
    appDefinitionId: PROFILE_WIDGET_APP.appDefinitionId,
  });
  const isHorizontal = await profileApi.isHorizontalLayout();
  res.x += isHorizontal === 0 ? CARD_LAYOUT_WIDTH : 0;

  const pageChildren = await getComponentChildren({ editorSDK, componentRef: pageRef });
  if (pageChildren?.length > 0) {
    for (const component of pageChildren) {
      const layout = await getComponentLayout({ editorSDK, componentRef: component });
      const y = layout.y + layout.height;
      res.y = y > res.y ? y : res.y;
    }
  }
  return res;
}
