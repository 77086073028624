import { PageWidget, IntegrationApplicationMap } from '@wix/members-area-integration-kit';

interface AppState {
  isDeletingSinglePage: boolean;
  isResponsiveEditor: boolean;
  isADI: boolean;
  integratedAppsMap: IntegrationApplicationMap;
  staticsBaseUrl: string;
  additionalWidgets: PageWidget[];
}

let applicationState: AppState = {
  isDeletingSinglePage: false,
  isResponsiveEditor: false,
  isADI: false,
  integratedAppsMap: {},
  staticsBaseUrl: '',
  additionalWidgets: [],
};

const setIsDeletingSinglePage = (isDeleting: boolean) => {
  applicationState = {
    ...applicationState,
    isDeletingSinglePage: isDeleting,
  };
};

const setIsResponsiveEditor = (isResponsiveEditor: boolean) => {
  applicationState = {
    ...applicationState,
    isResponsiveEditor,
  };
};

const setIsADI = (isADI: boolean) => {
  applicationState = {
    ...applicationState,
    isADI,
  };
};

const setStaticsBaseUrl = (staticsBaseUrl: string) => {
  applicationState = {
    ...applicationState,
    staticsBaseUrl,
  };
};

const setIntegratedApps = (integratedAppsMap: IntegrationApplicationMap) => {
  applicationState = {
    ...applicationState,
    integratedAppsMap,
  };
};

const setAdditionalWidgets = (additionalWidgets: PageWidget[]) => {
  applicationState = {
    ...applicationState,
    additionalWidgets,
  };
};

const getAdditionalWidgets = () => applicationState.additionalWidgets;

const getIsDeletingSinglePage = () => applicationState.isDeletingSinglePage;
const getIsResponsiveEditor = () => applicationState.isResponsiveEditor;
const getIsADI = () => applicationState.isADI;
const getStaticsBaseUrl = () => applicationState.staticsBaseUrl;
const getAllIntegratedApps = () => applicationState.integratedAppsMap || {};
const getVerticalsApps = (verticalAppDefId: string) => applicationState.integratedAppsMap[verticalAppDefId] || [];
const removeVerticalsApps = (verticalAppDefId: string) => {
  // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
  delete applicationState.integratedAppsMap[verticalAppDefId];
};

export {
  setIsDeletingSinglePage,
  getIsDeletingSinglePage,
  setIsResponsiveEditor,
  getIsResponsiveEditor,
  setIsADI,
  getIsADI,
  setStaticsBaseUrl,
  getStaticsBaseUrl,
  setIntegratedApps,
  getAllIntegratedApps,
  removeVerticalsApps,
  getVerticalsApps,
  setAdditionalWidgets,
  getAdditionalWidgets,
};
