import { logError } from '../utils/monitoring';
import _ from 'lodash';
import * as menus from './wrappers/menus';
import * as pages from './wrappers/pages.ts';
import * as pagesService from './services/pages';
import * as routers from './wrappers/routers';
import * as constants from './constants';

async function createMenuItem({ editorSDK, menuId, linkData }) {
  const appRouters = await routers.getAll(editorSDK);
  const menuItems = await menus.getMenuItems({ editorSDK, menuId });
  const newMenuItem = menus.createNewMenuItem(linkData);
  menuItems.push(newMenuItem);
  appRouters.forEach((router) => {
    return (
      router.config &&
      router.config.patterns &&
      Object.keys(router.config.patterns).forEach((pattern) => {
        const menuItem = menuItems
          .filter((item) => item.link?.routerId === router.id && `/${item.link.innerRoute}` === pattern)
          .pop();
        if (menuItem) {
          menuItem.order = router.config.patterns[pattern].appData.menuOrder || constants.DEFAULT_MENU_ORDER;
        }
      })
    );
  });

  const reorderedItems = _.sortBy(menuItems, (item) => item.order || constants.DEFAULT_MENU_ORDER);
  return await menus.updateMenuItems({ editorSDK, menuId, items: reorderedItems });
}

async function navigateToFirstPrivatePage(editorSDK) {
  const allRouters = await routers.getAll(editorSDK);
  const privateRouter = allRouters.find((router) => router.config.type === 'private');
  const privatePage = privateRouter.pages[0];

  if (!privatePage || !privatePage.pageRef) {
    const error = new Error('Could not retrieve a private page when navigating to first private page');
    logError(error, { extra: { allRouters: JSON.stringify(allRouters) } });
    throw error;
  }

  await pages.navigateToPageRef({ editorSDK, pageRef: privatePage.pageRef });
}

async function removePageFromMenus({ editorSDK, options }) {
  let { returnValue: removedInnerRoute } = await routers.onRouterPageDelete(
    editorSDK,
    options.pageRole,
    options.pageRef,
  );
  if (!removedInnerRoute) {
    return;
  }
  removedInnerRoute = removedInnerRoute.replace(/\/?(.*)/, '$1');
  await menus.removePatternFromAllMenus({ editorSDK, pattern: removedInnerRoute });
}

async function removePage({ editorSDK, options }) {
  const deleteResult = await routers.onRouterPageDelete(editorSDK, options.pageRole, options.pageRef);
  let { returnValue: removedInnerRoute } = deleteResult;
  if (!removedInnerRoute) {
    return;
  }
  removedInnerRoute = removedInnerRoute.replace(/\/?(.*)/, '$1');
  await menus.removePatternFromAllMenus({ editorSDK, pattern: removedInnerRoute });

  await editorSDK.document.application.reloadManifest();
}

async function isInMembersAreaSubPage(editorSDK) {
  const currentPageRef = await editorSDK.pages.getCurrent();
  const allMAPagesRefs = await pagesService.getAllMembersPagesRefs({ editorSDK });
  return !!allMAPagesRefs.find((pageRef) => pageRef.id === currentPageRef.id);
}

async function navigateToHomePage(editorSDK) {
  const pageRef = await pages.getHomePage({ editorSDK });
  return pages.navigateToPageRef({ editorSDK, pageRef });
}

export {
  removePage,
  navigateToFirstPrivatePage,
  createMenuItem,
  isInMembersAreaSubPage,
  navigateToHomePage,
  removePageFromMenus,
};
