import * as actions from './actions';
import * as pages from './pages';
import * as constants from '../constants';

const getRouterActions = (t, hasCustomPages) => ({
  showOnAllModes: true,
  default: [
    actions.getAddPageAction(t),
    actions.VIEW_SITE_MEMBERS,
    actions.OPEN_SIGNUP_SETTINGS,
    actions.getAdvancedURLSettingsAction(t),
    hasCustomPages && actions.getDeleteCustomPageAction(t),
    actions.getDeleteMembersAreaAction(t),
  ].filter((a) => !!a),
});

const createAppManifest = async (editorSDK, t) => ({
  controllersStageData: {
    members: {
      default: {
        visibility: 'NEVER',
      },
    },
  },
  pages: {
    pageDescriptors: {
      default: { icon: 'membersAction' },
    },
    applicationSettings: {
      default: {
        displayName: t('Pages_Panel_MemberMenu_Title'),
        helpId: constants.MEMBERS_PAGES_PANEL_HELP_ID,
      },
    },
    applicationActions: getRouterActions(t, false),
    pageSettings: {
      default: [pages.PAGE_INFO, pages.PERMISSIONS, pages.SEO],
    },
    pageActions: {
      default: [],
      deletable: [
        {
          title: t('Pages_Panel_MemberMenu_Actions_Page_Delete'),
          icon: 'deleteAction',
          event: 'removePage',
        },
      ],
      readOnly: [],
    },
  },
});

export { createAppManifest };
