import { instanceValue } from '@wix/wix-instance-parser-js/dist/src';
import { EditorSDK } from '@wix/platform-editor-sdk';
import webBiLogger from '@wix/web-bi-logger';
import initSchemaLogger, {
  loadMembersCustomPagesParams,
  addPageButtonClickMembersPagesParams,
  menuToggleParams,
  verticalTriggeredMaInstallInitiatedParams,
  verticalTriggeredMaInstallSuccessParams,
  maPageInstalledParams,
  Logger,
} from '@wix/bi-logger-members-app-users';
import { SANTA_MEMBERS_APP_ID } from '../editor-app-module/constants';

const createBIService = async ({ editorSDK, withClientId }: { editorSDK: EditorSDK; withClientId?: boolean }) => {
  const biService: BIService = new BIService();
  await biService.init(editorSDK, withClientId);
  return biService;
};

interface BaseProps {
  app_id?: string;
  instance_id?: string;
  biToken?: string;
  client_id?: string;
}

class BIService {
  logger: Logger;
  baseProps: BaseProps = {};

  constructor() {
    this.logger = initSchemaLogger(webBiLogger)();
  }

  init = async (editorSDK: EditorSDK, withClientId: boolean = true) => {
    const instance = await editorSDK.info.getAppInstance('');
    const instanceId = instanceValue.getValueFromInstance('instanceId', instance);
    const biToken = instanceValue.getValueFromInstance('biToken', instance);
    this.baseProps = { app_id: SANTA_MEMBERS_APP_ID, instance_id: instanceId, biToken };
    if (withClientId) {
      this.baseProps.client_id = instanceValue.getValueFromInstance('uid', instance);
    }
  };

  logMembersMenuToggle = ({ pageId, pageName, toggle_status, is_social }) => {
    const toggleName = 'Members Menu';
    const props = { ...this.baseProps, pageId, pageName, toggleName, toggle_status, is_social };
    return this.logger.menuToggle(props);
  };

  logLoginMenuToggle = ({ pageId, pageName, toggle_status, is_social }: Partial<menuToggleParams>) => {
    const toggleName = 'Login Menu';
    const props = { ...this.baseProps, pageId, pageName, toggleName, toggle_status, is_social };
    return this.logger.menuToggle(props);
  };

  logInstallationFailure = (errorMsg: string) => {
    const props = { ...this.baseProps, errorMsg, appInstanceId: this.baseProps.app_id };
    delete props.app_id;
    return this.logger.membersAreaInstallationError(props);
  };

  addPageButtonClick = ({ origin, pageName, pageId, appId }: Partial<addPageButtonClickMembersPagesParams>) => {
    return this.logger.addPageButtonClickMembersPages({ ...this.baseProps, origin, pageName, pageId, appId });
  };

  loadMembersCustomPages = ({ pageName, pageId, appId }: Partial<loadMembersCustomPagesParams>) => {
    return this.logger.loadMembersCustomPages({ ...this.baseProps, pageName, pageId, appId });
  };

  verticalTriggeredMaInstallInitiated = ({ originAppId }: Partial<verticalTriggeredMaInstallInitiatedParams>) => {
    return this.logger.verticalTriggeredMaInstallInitiated({ ...this.baseProps, originAppId });
  };

  verticalTriggeredMaInstallSuccess = ({ originAppId }: Partial<verticalTriggeredMaInstallSuccessParams>) => {
    return this.logger.verticalTriggeredMaInstallSuccess({ ...this.baseProps, originAppId });
  };

  maPageInstalled = ({ originAppId, pageName }: Partial<maPageInstalledParams>) => {
    return this.logger.maPageInstalled({ ...this.baseProps, originAppId, pageName });
  };

  managePagesActionDelete = ({ pageId, pageName, value, appId }) => {
    return this.logger.managePagesActionDelete({ ...this.baseProps, pageId, pageName, value, appId });
  };

  managePagesAddMemberPagesClick = ({ origin }) => {
    return this.logger.managePagesAddMemberPagesClick({ ...this.baseProps, origin });
  };

  managesPagesModalTabNavigation = ({ tabName }) => {
    return this.logger.managesPagesModalTabNavigation({ ...this.baseProps, tabName });
  };
}

export { createBIService, BIService };
